/* Font adjustment */
body {
  font-family: Arial, sans-serif;
}

/* Global Button changes */
button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 1em;
  color: white;
  cursor: pointer;
}

.parameter {
  border: 3px solid #ddd;
  border-radius: 7px;
}

.valid {
  border-color: #3c763d;
}

.invalid {
  border-color: #a94442;
}

/* ResultsPanel Results box styling for box, code, and loading circle */
.results-box {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #eeeeee;
  overflow: auto;
  height: 60vh;
  /*60% of the vertical screen size*/
}

pre {
  margin-top: 0;
  background-color: #eeeeee;
  font-family: monospace;
  white-space: pre-wrap;
  /* Preserve line breaks and wrap long lines */
}

.loader {
  margin-top: 50px;
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid grey;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
